'use client';
import React, { useState } from 'react';
import { useAuth, useSignIn } from '@clerk/nextjs';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import styles from './auth.module.scss';
import LogoMark from '@nex/icons/svg/logo-mark.svg';
import { CallToAction, Container, Field, Flex, Heading, Text } from '@nex/labs';

export const ForgotPasswordPage: NextPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [error, setError] = useState('');

  const router = useRouter();
  const { isSignedIn } = useAuth();
  const { isLoaded, signIn, setActive } = useSignIn();

  if (!isLoaded) {
    return null;
  }

  // If the user is already signed in,
  // redirect them to the home page
  if (isSignedIn) {
    router.push('/');
  }

  // Send the password reset code to the user's email
  async function create(e: React.FormEvent) {
    e.preventDefault();
    await signIn
      ?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      .then((_) => {
        setSuccessfulCreation(true);
        setError('');
      })
      .catch((err) => {
        console.error('error', err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }

  // Reset the user's password.
  // Upon successful reset, the user will be
  // signed in and redirected to the home page
  async function reset(e: React.FormEvent) {
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })
      .then((result) => {
        // Check if 2FA is required
        if (result.status === 'needs_second_factor') {
          setSecondFactor(true);
          setError('');
        } else if (result.status === 'complete') {
          // Set the active session to
          // the newly created session (user is now signed in)
          setActive({ session: result.createdSessionId });
          setError('');
        } else {
          setError('An unexpected error occurred');
        }
      })
      .catch((err) => {
        console.error('error', err.errors[0].longMessage);
        setError(err.errors[0].longMessage);
      });
  }

  return (
    <Container maxWidth="500px">
      <div className={styles.InnerWrapLayout}>
        <Flex.Column gap={8} className="mb-5">
          <LogoMark width={30} height={30} />
          <Heading.h5 weight={700}>Forgot Password?</Heading.h5>
        </Flex.Column>

        <Field.Form
          onSubmit={(e) => {
            e.preventDefault();
            if (successfulCreation) {
              reset(e);
            } else {
              create(e);
            }
          }}
        >
          {secondFactor && (
            <Text color="var(--primary-red)">
              2FA is required, but this UI does not handle that
            </Text>
          )}
          {error && <Text color="var(--primary-red)">{error}</Text>}

          {!successfulCreation && (
            <>
              <Field.Input
                label="Please provide your email address"
                type="email"
                placeholder="e.g john@doe.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <CallToAction.button>Send reset code</CallToAction.button>
              {error && <p>{error}</p>}
            </>
          )}

          {successfulCreation && (
            <>
              <Field.Input
                label="Enter the password reset code that was sent to your email"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
              <br />
              <div className={!code ? 'opacity-50' : ''}>
                <Field.Input
                  label="Enter your new password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <CallToAction.button>Reset password</CallToAction.button>
            </>
          )}
        </Field.Form>
      </div>
    </Container>
  );
};

export default ForgotPasswordPage;
