import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import { AuthLayout } from '@/components/layout';
import {
  Heading,
  Flex,
  Text,
  CallToAction,
  Modal,
  Field,
  slugify,
  useFeedback,
} from '@nex/labs';

import { useAuth } from './hooks/useAuth';
import {
  useAuth as useClerkAuth,
  useOrganizationList,
  useClerk,
  useOrganization,
} from '@clerk/nextjs';

import BusinessIcon from '@nex/icons/svg/misc/business.svg';
import UserIcon from '@nex/icons/svg/misc/user-circle.svg';
import Logo from '@nex/icons/svg/logo-mark.svg';
import { type TUser } from '@nex/types';

import styles from './auth.module.scss';
import { usePostHog } from 'posthog-js/react';

export const Onboarding = () => {
  const router = useRouter();
  const [teamOpen, setTeamOpen] = React.useState(false);
  const { handleAuthSuccess } = useAuth();
  const { createToast } = useFeedback();
  const { createOrganization, setActive } = useOrganizationList();
  const { userId, isLoaded } = useClerkAuth();
  const { user } = useClerk();
  const [choice, setChoice] = React.useState<any>('personal');
  const [isLoading, setIsLoading] = React.useState(false);

  const createNewOrganization = async (name?: string) => {
    setIsLoading(true);
    try {
      const new_organization = await createOrganization?.({
        name: name || `${user?.firstName}'s Workspace`,
        slug: slugify(name || user?.fullName || user?.firstName!, true),
      });

      if (new_organization) {
        await setActive?.({ organization: new_organization.id });
      }

      user?.update({
        unsafeMetadata: {
          onboardingComplete: true,
        },
      });

      handleAuthSuccess(
        {
          ...user,
          organizationId: new_organization?.id,
        } as TUser,
        {
          goTo: '/?watch=tutorial',
          isNewUser: true,
        }
      );
    } catch (error) {
      createToast({
        variant: 'error',
        message: 'An error occurred. Please try again',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { organization, isLoaded: isLoadedOrganization } = useOrganization();
  const postHog = usePostHog();
  useEffect(() => {
    if (isLoaded || isLoadedOrganization) {
      if (user?.unsafeMetadata?.onboardingComplete || organization?.id) {
        router.push('/');
      }

      if (!userId) {
        router.push('/login');
      }
    }
  }, [
    userId,
    router,
    isLoaded,
    user?.unsafeMetadata?.onboardingComplete,
    organization?.id,
    isLoadedOrganization,
  ]);

  return (
    <AuthLayout title="How do you want to use NEX">
      <Flex.Column gap={28} className={styles.InnerWrapLayout}>
        <Flex.Column gap={28} flex="1">
          <Flex.Column gap={14} justifyContent="center" alignItems="center">
            <Logo width="60" height="60" />
            <Heading.h3 weight={700}>
              How are you planning to use NEX?
            </Heading.h3>
          </Flex.Column>

          <Flex flexWrap="wrap" gap={12}>
            <button
              className={classNames([
                styles.OnboardingButton,
                choice === 'personal' && styles.isActive,
              ])}
              onClick={() => setChoice('personal')}
            >
              <span>
                {' '}
                <UserIcon />
              </span>
              <Heading.h6 weight={700}>For personal use</Heading.h6>
              <Text weight={500}>
                Design Better, express your thoughts clearly. stay organized
              </Text>
            </button>
            <button
              className={classNames([
                styles.OnboardingButton,
                choice === 'company' && styles.isActive,
              ])}
              onClick={() => setChoice('company')}
            >
              <span>
                <BusinessIcon />
              </span>
              <Heading.h6 weight={700}>For my team</Heading.h6>
              <Text weight={500}>
                Collaborate on your designs, <br /> assets and ideas.
              </Text>
            </button>
          </Flex>

          <CallToAction.button
            className="mt-3 mx-auto w-fit"
            disabled={!choice}
            isLoading={isLoading}
            onClick={async () => {
              postHog.capture('auth_onboarding', {
                choice,
              });
              if (choice === 'personal') {
                createNewOrganization();
              } else {
                setTeamOpen(true);
              }
            }}
          >
            Get Started
          </CallToAction.button>
        </Flex.Column>
      </Flex.Column>
      <Modal
        in={teamOpen}
        size="md"
        title="Create your organization"
        onClose={() => {
          setTeamOpen(false);
        }}
      >
        <Flex.Column gap={28}>
          <Field.Form
            onSubmit={async (e) => {
              e.preventDefault();

              const { name } = e.target as any;

              createNewOrganization(name.value);
            }}
          >
            <Field.Input
              label="What would you like to name your organization?"
              name="name"
              placeholder="Enter your organization name"
              required
            />
            <CallToAction.button isLoading={isLoading}>
              Create Organization
            </CallToAction.button>
          </Field.Form>
        </Flex.Column>
      </Modal>
    </AuthLayout>
  );
};
