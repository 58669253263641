import React, { useEffect } from 'react';

import { AuthLayout } from '@/components/layout';

import { SignUp } from '@clerk/nextjs';

export const CreateAccount = () => {
  return (
    <AuthLayout title="Create an Account">
      <SignUp routing="hash" />
    </AuthLayout>
  );
};
