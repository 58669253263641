import React from 'react';

import { AuthLayout } from '@/components/layout';
import { SignIn } from '@clerk/nextjs';

export const Login = () => {
  return (
    <AuthLayout title="Login to your Nex Account">
      <SignIn routing="hash" />
    </AuthLayout>
  );
};
