import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { useUserStore } from '@/state/useStore';
import { type TUser } from '@nex/types';

const ALLOWED_REDIRECT_PATHS = [
  '/login',
  '/create-account',
  '/forgot-password',
];

const DEFAULT_REDIRECT_PATH = '/';

export const useAuth = () => {
  const { setUser } = useUserStore();
  const router = useRouter();

  const handleAuthSuccess = useCallback(
    async (
      user: Partial<TUser> | null,
      options?: {
        isNewUser?: boolean;
        goTo?: string;
      }
    ) => {
      if (user) {
        setUser({ user });
      }

      if (!Cookies.get('__session')) router.reload();

      let redirectTo = '';

      if (router.query.redirectTo) {
        redirectTo = Array.isArray(router.query.redirectTo)
          ? router.query.redirectTo[0]
          : router.query.redirectTo;
      }

      const decodedRedirectTo = decodeURIComponent(redirectTo);

      if (
        !ALLOWED_REDIRECT_PATHS.find(
          (path) => !path.startsWith(decodedRedirectTo)
        )
      ) {
        redirectTo = DEFAULT_REDIRECT_PATH;
      }

      // Handle additional options
      if (options?.goTo) {
        // If a custom redirect path is provided, use it
        return window?.location?.replace(options.goTo);
      } else if (options?.isNewUser) {
        // If it's a new user, redirect to the tutorial
        return window?.location?.replace(
          `/?watch=tutorial&redirectTo=${redirectTo}`
        );
      } else {
        // Otherwise, use the default or allowed redirect path
        return window?.location?.replace(redirectTo);
      }
    },
    [setUser, router]
  );

  return {
    handleAuthSuccess,
  };
};

export default useAuth;
